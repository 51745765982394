import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { FeatureAverageDownload } from '@netcheck/feature/average-download';
import { FeatureAverageUpload } from '@netcheck/feature/average-upload';
import { FeatureTwampRtt } from '@netcheck/feature/twamp-rtt';
import { FeatureDnsResolveTime } from '@netcheck/feature/dns-resolve-time';
import { FeaturePacketLoss } from '@netcheck/feature/packet-loss';
import { FeaturePreviewPDF } from '@netcheck/feature/preview-pdf';
import { UiDateFilter } from '@netcheck/ui/date-filter'
import { FeatureDashboardData } from '@netcheck/feature/dashboard-data';
import { deviceIdSelector } from '@netcheck/state/global';

import { StyledDashboard, StyledGrid, FlexGrid } from './dashboard.styles';

export const Dashboard = () => {
  const { t } = useTranslation();

  const [previewPDF, setPreviewPDF] = useState(false)
  const deviceId = useSelector(deviceIdSelector);


  return (
    <StyledDashboard>
      <StyledGrid container spacing={2} >
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {deviceId && <FeatureDashboardData />}
            <Grid item lg={6} xs={12}>
              <UiDateFilter />
            </Grid>
          </Grid>
          {deviceId && <>
            <Grid container spacing={2}>
              <FlexGrid item lg={6} xs={12}>
                <FeatureAverageDownload />
              </FlexGrid>
              <FlexGrid item lg={6} xs={12}>
                <FeatureAverageUpload />
              </FlexGrid>
            </Grid>
            <Grid container spacing={2}>
              <FlexGrid item lg={5} xs={12}>
                <FeatureTwampRtt />
              </FlexGrid>
              <Grid item lg={7} xs={12}>
                <Grid container spacing={2}>
                  <FlexGrid item lg={6} xs={12}>
                    <FeatureDnsResolveTime />
                  </FlexGrid>
                  <FlexGrid item lg={6} xs={12}>
                    <FeaturePacketLoss />
                  </FlexGrid>
                </Grid>
              </Grid>
            </Grid>
          </>}
        </Grid>
      </StyledGrid>
    </StyledDashboard>

  );
};

export default Dashboard;
