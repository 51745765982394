import styled from 'styled-components';
import { Grid } from '@mui/material';

export const StyledDashboard = styled.div`
  background-color: ${(props) => props.theme.basicColors.BackgroundColor1};   
  width: 100%;
  padding-top: 66px;
`;
export const StyledGrid = styled(Grid)`
  padding: 25px 40px;
  @media (max-width: 450px) { 
  padding: 25px 10px;
  }
`
export const FlexGrid = styled(Grid)`
  @media (max-width: 1249px) and (min-width: 1200px) { 
  *{
    font-size: 14px;
  }
  h1{
    font-size:14px;
  }
  svg{
    font-size: 1.3rem
  }
  }
@media (max-width: 1360px) and (min-width: 1250px) { 
  *{
    font-size: 14px;
  }
  h1{
    font-size:16px;
  }
  svg{
    font-size: 1.3rem
  }
  }
`