import React from 'react';
import { Menu, MenuItem, IconButton, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import styled from 'styled-components';
import { useKeycloak } from '@react-keycloak/web';

const StyledSettingsDropdown = styled.div`
  color: ${(props) => props.theme.basicColors.PrimaryColor1};
  display: flex;
  align-items: center;
`;

const VisibilitySpan = styled.span`
   @media (max-width: 700px) { 
    display:none
  }
`;

export const SettingsDropdown = () => {
  const { keycloak } = useKeycloak();
  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    keycloak.accountManagement()
  };

  const handleLogout = () => {
    keycloak.logout();
    handleClose();
  };
  return (
    <StyledSettingsDropdown >
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <VisibilitySpan >
          {t('Account Settings')}
        </VisibilitySpan>
        <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
          <PersonOutlineIcon sx={{ width: 32, height: 32, backgroundColor: "#898989", borderRadius: "16px", color: 'white', padding: '4px' }} />
        </IconButton>
      </Box>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            minWidth: 140,
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleProfile}><PersonOutlineIcon sx={{ marginRight: '6px' }} /> {t("Profile")}</MenuItem>
        <MenuItem onClick={handleLogout}><LogoutIcon sx={{ marginRight: '6px' }} /> {t("Logout")}</MenuItem>
      </Menu>
    </StyledSettingsDropdown>
  );
};

export default SettingsDropdown;