import { Typography } from '@mui/material';
import styled from 'styled-components';

/* eslint-disable-next-line */
export interface InfoItemProps {
  title: string;
  info: string | null;
  logo?: string;
}

const StyledLogo = styled.img`
  display: block;
  width: 140px;
  margin-top:16px;
`;

const StyledInfoItem = styled.div`
  margin-bottom: 32px;
`;

const StyledInfo = styled.p`
  font-weight: bold;
  font-size: 16px;
`;

const StyledTitle = styled(Typography)`
  color: ${(props) => props.theme.basicColors.PrimaryColor2};
  font-size: 14px;
  border-bottom: 1px solid  ${(props) => props.theme.basicColors.PrimaryColor5};
  padding-bottom: 4px;
`;

export const InfoItem = ({ title, logo, info }: InfoItemProps) => {
  return (
    <StyledInfoItem>
      <StyledTitle>{title}:</StyledTitle>
      {/* {logo && <StyledLogo src={`/assets/logos/deutsche_telekom.svg`} />} */}
      <StyledInfo>{info}</StyledInfo>
    </StyledInfoItem>
  );
};


export default InfoItem;
