import styled from 'styled-components';
import { ITheme } from '@netcheck/utils-color-palette'

export type TypeVariant = 'small' | 'large'

type WidgetDivProps = {
    variant: TypeVariant
    children: number | string
    theme: ITheme
  }

export const TextDiv = styled.div.attrs((props: WidgetDivProps) => props)`
  font-size: ${({ variant }) => variant === 'small' ? '12px' : '14px'};
  font-weight: 500;
`

export const ValueDiv = styled.div.attrs((props: WidgetDivProps) => props)`
  font-size: ${({ variant }) => variant === 'small' ? '12px' : '17px'};
  font-weight: 600;
`