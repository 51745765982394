import { api } from "@netcheck/utils/api";
import { getInterval } from "@netcheck/utils/functions/get-interval";
import {
  ICustomDateRange,
  MetricInterval,
  Status,
  intervalEnum
} from "@netcheck/utils/types";
import {
  ActionReducerMapBuilder,
  createEntityAdapter,
  createSlice,
  createAsyncThunk
} from "@reduxjs/toolkit";

export const GLOBAL_FEATURE_KEY = "global";

export interface IOperatorData {
  loadingStatus: Status;
  error: string | null;
  data: IData;
}

export interface IData {
  deviceId: string | null;
  operatorId: string | null;
}

export interface IGlobalState {
  isDrawerOpen: boolean;
  metricType: MetricInterval;
  operatorData: IOperatorData;
  dateRange: ICustomDateRange;
  lastDateRange: ICustomDateRange;
}

export const globalAdapter = createEntityAdapter();

export const fetchOperatorData = createAsyncThunk(
  "global/fetchOperatorData",
  async () => {
    try {
      const res = await api.get(`/users/myself`);
      return {
        ...res.data
      };
    } catch (error) {
      return error;
    }
  }
);

export const initialGlobalState: IGlobalState = globalAdapter.getInitialState({
  isDrawerOpen: false,
  metricType: "Daily",
  operatorData: {
    loadingStatus: Status.NEW,
    error: null,
    data: {
      deviceId: null,
      operatorId: null
    }
  },
  dateRange: getInterval(intervalEnum.custom_Interval),
  lastDateRange: getInterval(intervalEnum.custom_Interval)
});

export const globalSlice = createSlice({
  name: GLOBAL_FEATURE_KEY,
  initialState: initialGlobalState,
  reducers: {
    setDrawerOpen: (state, { payload }) => {
      state.isDrawerOpen = payload;
    },
    setMetricType: (state, { payload }) => {
      state.metricType = payload;
    },
    setDataRange: (state, { payload }) => {
      state.dateRange = payload;
    },
    setLastDateRange: (state, { payload }) => {
      state.lastDateRange = payload;
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<IGlobalState>) => {
    builder
      .addCase(fetchOperatorData.pending, (state: IGlobalState) => {
        state.operatorData.loadingStatus = Status.ONGOING;
      })
      .addCase(fetchOperatorData.fulfilled, (state: IGlobalState, action) => {
        state.operatorData.loadingStatus = Status.SUCCESS;
        state.operatorData.data = action.payload;
      })
      .addCase(fetchOperatorData.rejected, (state: IGlobalState, action) => {
        state.operatorData.loadingStatus = Status.ERROR;
        state.operatorData.error = action.error.message || "";
      });
  }
});

export const globalReducer = globalSlice.reducer;
export const globalActions = globalSlice.actions;
