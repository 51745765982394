import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';


const StyledUiNotLogged = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  width: 100vw;
  height: 100vh;
`;


export function UiNotLogged() {
  return (
    <StyledUiNotLogged>
      <CircularProgress />
    </StyledUiNotLogged>
  );
};


export default UiNotLogged;
