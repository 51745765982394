import { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Radio, RadioGroup, FormControlLabel, FormControl, Grid } from '@mui/material'
import { metricTypeSelector, globalActions, lastDateRangeSelector } from '@netcheck/state/global';
import { intervalEnum, RadioValueType } from '@netcheck/utils/types'
import { getInterval } from '@netcheck/utils/functions/get-interval';
interface IStyledFormControlLabel {
  reversed?: boolean
}

const StyledFormControlLabel = styled(FormControlLabel)`
  flex-direction: ${(props: IStyledFormControlLabel) => (props.reversed ? "row" : "row-reverse")};
  justify-content: ${(props: IStyledFormControlLabel) => (props.reversed ? "flex-start" : "space-between")};
  .Mui-checked{
    color: ${(props) => props.theme.basicColors.PrimaryColor1};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-top: 2px;
    margin-right: 5px;
    margin-left: 10px;
  }
  span{  
    padding: 2px 0px;
    font-weight: 500;
    font-size: 14px;  
    margin-left: ${(props: IStyledFormControlLabel) => (props.reversed ? "2px" : "3px")};

  };
  span:nth-last-child(1){
    margin-left: ${(props: IStyledFormControlLabel) => (props.reversed ? "10px" : "auto")};
    @media (min-width: 1350px) {
      margin-right: 5px;
    }
  }
  span:first-child(1){
    margin-left: 10px;
  }
`
const StylingDiv = styled.div`
  margin-top: 20px;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-top: 10px;
  }
`

const RadioTitleParagraph = styled.p`
  font-weight: 600;
  margin-bottom: 30px;
  font-size: 12px;
`;
interface IRadioComponent {
  reversed?: boolean,
  isGenerating?: boolean,
  setDocumentReady?: (arg: boolean) => void,
  setPreviewPDF?: (arg: boolean) => void
}

export const RadioComponent = ({ reversed, isGenerating, setDocumentReady, setPreviewPDF }: IRadioComponent) => {
  const { t } = useTranslation()
  const metricType = useSelector(metricTypeSelector);
  const lastDateRange = useSelector(lastDateRangeSelector)
  const dispatch = useDispatch()
  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>) => {


    if (metricType === RadioValueType.hourly_view) {
      if (lastDateRange.interval) {
        dispatch(globalActions.setDataRange(lastDateRange))
      } else {
        dispatch(globalActions.setDataRange(getInterval(intervalEnum.last_30_days)))
      }
    }
    dispatch(globalActions.setMetricType(e.target.value))

    if (setDocumentReady) setDocumentReady(false)
    if (setPreviewPDF) setPreviewPDF(false)

  }
  return (
    <StylingDiv>
      <FormControl component="fieldset" disabled={isGenerating}>
        {reversed && <RadioTitleParagraph>{t('Type')}</RadioTitleParagraph>}
        <RadioGroup defaultValue={metricType} name="radio-buttons-group" value={metricType} onChange={handleRadioChange}>
          <Grid container sx={{ flexDirection: { xs: "row", md: "column" } }} spacing={2}>
            <StyledFormControlLabel reversed={reversed} value={"Daily"} control={<Radio />} label={t(RadioValueType.daily_view).toString()} />
            <StyledFormControlLabel reversed={reversed} value={"Hour"} control={<Radio />} label={t(RadioValueType.hourly_view).toString()} />
          </Grid>
        </RadioGroup>
      </FormControl>
    </StylingDiv>
  );
};

export default RadioComponent;
