import { useEffect } from "react";
import styled from "styled-components";
import { Stack, Popover, Divider } from "@mui/material";
import { intervalEnum, RadioValueType } from "@netcheck/utils/types";
import { useDispatch, useSelector } from "react-redux";
import { dateRangeSelector, globalActions, metricTypeSelector } from "@netcheck/state/global";
import { DatePicker } from "./ui-date-picker";
import { StyledDiv, StyledPopover } from "./styles";

interface IPikcerContainer {
  anchorEl: null | HTMLElement;
  handleClose: () => void;
}

export const DatePickerContainer = ({
  anchorEl,
  handleClose,
}: IPikcerContainer) => {
  const dispatch = useDispatch();
  const metricType = useSelector(metricTypeSelector);
  const { startDate, endDate, interval } = useSelector(dateRangeSelector);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : "";

  useEffect(() => {
    if (metricType === RadioValueType.hourly_view) {
      dispatch(
        globalActions.setDataRange({
          startDate: endDate !== 'Invalid date' ? endDate : startDate,
          endDate: endDate !== 'Invalid date' ? endDate : startDate,
          interval:
            interval === intervalEnum.today ||
              interval === intervalEnum.yesterday
              ? interval
              : intervalEnum.custom_Interval,
        })
      );
    }
  }, [metricType, dispatch, endDate, interval]);

  return (
    <StyledDiv>
      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}>
        <Stack
          direction={{ xs: "column-reverse", lg: "row" }}
          spacing={0}
          divider={<Divider orientation="vertical" flexItem />}>
          <DatePicker
            handleClose={handleClose}
            isDaily={metricType === RadioValueType.daily_view}
            inModal={false} />
        </Stack>
      </StyledPopover>
    </StyledDiv>
  );
};
