import { Grid } from '@mui/material';
import { HeaderTableRaport } from '@netcheck/ui/header-table-raport'
import { ExtremeDataTableReport } from '@netcheck/ui/pdf-raport-extreme-data-table'
import { GraphGridContainer, StyledFeaturePreviewPDF } from '@netcheck/feature/preview-pdf';
import { FeatureAverageDownload } from '@netcheck/feature/average-download';
import { FeatureAverageUpload } from '@netcheck/feature/average-upload';
import { FeatureTwampRtt } from '@netcheck/feature/twamp-rtt';
import { FeatureDnsResolveTime } from '@netcheck/feature/dns-resolve-time';
import { FeaturePacketLoss } from '@netcheck/feature/packet-loss';

interface IRaportPreview {
  isDaily: boolean,
  dailyData: any,
  singleDayDataArr: any
}

export const RaportPreview = ({ isDaily, dailyData, singleDayDataArr }: IRaportPreview) => {

  return (

    <Grid>
      <HeaderTableRaport />
      {
        isDaily &&
        <Grid   >
          <Grid container spacing={2}  >
            <GraphGridContainer item xs={12} id={`captureDaily1`} >
              <FeatureAverageDownload dataProps={dailyData.download ? dailyData.download : {}} isPDF={true} />
            </GraphGridContainer>
            <GraphGridContainer item xs={12} id={`captureDaily2`} >
              <FeatureAverageUpload dataProps={dailyData.upload ? dailyData.upload : {}} isPDF={true} />
            </GraphGridContainer>
          </Grid>
          <Grid container spacing={2} >
            <GraphGridContainer item lg={12} xs={12} id={`captureDaily3`} >
              <FeatureTwampRtt dataProps={dailyData.twamp ? dailyData.twamp : {}} isPDF={true} />
            </GraphGridContainer>
            <GraphGridContainer item lg={12} xs={12} id={`captureDaily4`} >
              <Grid container spacing={2}>
                <Grid item lg={6} xs={6}>
                  <FeatureDnsResolveTime dataProps={dailyData.dns ? dailyData.dns : {}} isPDF={true} />
                </Grid>
                <Grid item lg={6} xs={6}>
                  <FeaturePacketLoss dataProps={dailyData.twamp ? dailyData.twamp : {}} isPDF={true} />
                </Grid>
              </Grid>
            </GraphGridContainer>
          </Grid>
        </Grid>
      }

      {
        singleDayDataArr?.length && singleDayDataArr.map((el: any, i: any) => (
          <Grid key={i}>
            <Grid container spacing={2} key={`${i}1`} >
              <GraphGridContainer item xs={12} id={`capture${i}1`}>
                <FeatureAverageDownload dataProps={el.downloadPerDay ? el.downloadPerDay : {}} isPDF={true} />
              </GraphGridContainer>
              <GraphGridContainer item xs={12} id={`capture${i}2`}>
                <FeatureAverageUpload dataProps={el.uploadPerDay ? el.uploadPerDay : {}} isPDF={true} />
              </GraphGridContainer>
            </Grid>
            <Grid container spacing={2} key={`${i}2`}>
              <GraphGridContainer item lg={12} xs={12} id={`capture${i}3`}>
                <FeatureTwampRtt dataProps={el.twampPerDay ? el.twampPerDay : {}} isPDF={true} />
              </GraphGridContainer>
              <GraphGridContainer item lg={12} xs={12} id={`capture${i}4`}>
                <Grid container spacing={2}>
                  <Grid item lg={6} xs={6}>
                    <FeatureDnsResolveTime dataProps={el.dnsPerDay ? el.dnsPerDay : {}} isPDF={true} />
                  </Grid>
                  <Grid item lg={6} xs={6}>
                    <FeaturePacketLoss dataProps={el.twampPerDay ? el.twampPerDay : {}} isPDF={true} />
                  </Grid>
                </Grid>
              </GraphGridContainer>
            </Grid>
          </Grid>
        )
        )
      }
    </Grid>
  )
}

export default RaportPreview