import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import * as htmlToImage from "html-to-image";
import { metricTypeSelector, dateRangeSelector } from "@netcheck/state/global";
import {
  fetchDeviceData,
  totalDataSelector,
} from "@netcheck/state/device-data";
import { Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { StyledFeaturePreviewPDF } from "./styles/feature-preview-pdf.styles";
import { DownloadLink } from "@netcheck/ui/pdf-download-link";
import { RaportPreview } from "@netcheck/ui/pdf-raport-preview";
import { useTranslation } from "react-i18next";

interface IMetricsObject {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: { [key: string]: any };
}
interface IFeaturePreviewPDF {
  setNumOfGeneratedGraphs: any;
  setDocumentReady: (arg: boolean) => void;
  downloadRef?: any;
  setPreviewPDF: (arg: boolean) => void;
  setIsGenerating: (arg: boolean) => void;
}

export const FeaturePreviewPDF = ({
  setIsGenerating,
  setNumOfGeneratedGraphs,
  setDocumentReady,
  downloadRef,
  setPreviewPDF,
}: IFeaturePreviewPDF) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [image, setImage] = useState([]);
  const [dailyImages, setDailyImages] = useState<string[]>([]);
  const [headerTableImage, setHeaderTableImage] = useState("");
  const screensGenerating = useRef<null | boolean>(false);

  const { startDate, endDate } = useSelector(dateRangeSelector);
  const totalData = useSelector(totalDataSelector);
  const isDaily = useSelector(metricTypeSelector) === "Daily";

  const metricsObject: IMetricsObject = {};

  interface ITotalDataMetrics {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }

  totalData?.metrics?.forEach((el: ITotalDataMetrics) => {
    metricsObject[el.type] = el;
  });

  const dailyData = {
    download: metricsObject?.downloadDaily,
    upload: metricsObject?.uploadDaily,
    dns: metricsObject?.dnsDaily,
    twamp: metricsObject?.twampDaily,
  };

  const a = moment(startDate, "MM-DD-yyyy");
  const b = moment(endDate, "MM-DD-yyyy");
  const numOfDays = b.diff(a, "days");

  const metricsPerDay = [
    "dnsPerDay",
    "downloadPerDay",
    "uploadPerDay",
    "twampPerDay",
  ];

  const dataProcessing: any = () => {
    const eachDayData: any[] = [];

    const isEmpty = Object.keys(metricsObject).length === 0;

    if (isEmpty) return;

    for (let i = 0; i <= numOfDays; i++) {
      const currDay = moment(startDate, "MM-DD-yyyy")
        .add(i, "days")
        .format("yyyy-MM-DD");
      const singleDayMetrics: any = { currentDay: currDay };
      for (const metricType of metricsPerDay) {
        if (metricsObject?.[metricType]?.entries) {
          for (const el of metricsObject?.[metricType]?.entries) {
            if (el.dayDate === currDay) {
              singleDayMetrics[metricType] = el;
            }
          }
        }
      }
      eachDayData[i] = singleDayMetrics;
    }

    return eachDayData;
  };

  const singleDayDataArr = dataProcessing();

  useEffect(() => {
    if (singleDayDataArr && !screensGenerating.current) {
      screensGenerating.current = true;
      handleCapture();
    }
  });

  const onCapture = async () => {
    await htmlToImage
      .toPng(document.getElementById(`headerTable`) as HTMLElement)
      .then((dataUrl) => {
        setHeaderTableImage(dataUrl);
      });

    if (isDaily) {
      const dailyImagesAggregation: string[] = [""];

      for (let i = 1; i <= 4; i++) {
        await htmlToImage
          .toPng(document.getElementById(`captureDaily${i}`) as HTMLElement)
          .then((dataUrl) => {
            dailyImagesAggregation[i] = dataUrl;
          });
      }
      setDailyImages(dailyImagesAggregation);
    }

    const imagesArr: any = [];
    for (let i = 0; i < singleDayDataArr?.length; i++) {
      imagesArr[i] = [];
      imagesArr[i][0] = singleDayDataArr[i].currentDay;

      for (let j = 1; j < 5; j++) {
        await htmlToImage
          .toPng(document.getElementById(`capture${i}${j}`) as HTMLElement)
          .then((dataUrl) => {
            imagesArr[i][j] = dataUrl;
          });
      }
      setNumOfGeneratedGraphs((num: number) => num + 1);
    }
    return imagesArr;
  };

  const handleCapture = async () => {
    const data = await onCapture();
    setImage(data);
  };

  useEffect(() => {
    dispatch(fetchDeviceData(""));
  }, []);

  return (
    <StyledFeaturePreviewPDF
      style={{ position: "absolute", left: "10000px", width: "1000px" }}
    >
      <div className="App">
        {image.length && (
          <DownloadLink
            setNumOfGeneratedGraphs={setNumOfGeneratedGraphs}
            setIsGenerating={setIsGenerating}
            setPreviewPDF={setPreviewPDF}
            downloadRef={downloadRef}
            headerTableImage={headerTableImage}
            dailyImages={dailyImages}
            startDate={startDate}
            endDate={endDate}
            isDaily={isDaily}
            image={image}
            setDocumentReady={setDocumentReady}
          />
        )}
      </div>

      <Typography>
        {moment(startDate, "MM-DD-yyyy").format("yyyy-MM-DD")} -{" "}
        {moment(endDate, "MM-DD-yyyy").format("yyyy-MM-DD")}
      </Typography>
      <button onClick={handleCapture} style={{ padding: "20px" }}>
        {t("Generate PDF")}
      </button>

      <RaportPreview
        isDaily={isDaily}
        dailyData={dailyData}
        singleDayDataArr={singleDayDataArr}
      />
    </StyledFeaturePreviewPDF>
  );
};

export default FeaturePreviewPDF;
