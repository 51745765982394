import styled from "styled-components";

export const StyledUiLineChart = styled.div`
  background-color: white;
  margin-top: 12px;
  padding: 12px 8px 0;
  height: 290px;
  border-radius: ${({ theme }) => theme.border.Radius};
  /* box-shadow: ${({ theme }) => theme.shadows.ChartShadow}; */
`;

export const StyledListItem = styled.li`
  color: ${(props) => props.color};
  margin-right: 40px;
  ::marker {
    font-size: 20px;
  }
`;
export const StyledLine = styled.span`
  background-image: linear-gradient(
    to right,
    black 33%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 10px 1px;
  background-repeat: repeat-x;
  margin-top: 2px;
  margin-right: 6px;
  height: 10px;
  width: 30px;
`;
export const StyledBenchmark = styled.span`
  display: flex;
`;

export const StyledLegend = styled.ul`
  display: flex;
  flex-wrap: wrap;
  line-height: 22px;
`;
