import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import styled from 'styled-components';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import { Box, Drawer, MenuItem } from '@mui/material';
import { isDrawerOpenSelector, globalActions } from '@netcheck/state/global';
import { ModalButton } from '@netcheck/ui-modal-button'
import chipLogo from './assets/chip-logo.png';
import netcheckLogo from './assets/netcheck-logo.png';
import ClientInfo from './client-info/client-info';
import { PdfDownloadModal } from '@netcheck/ui/pdf-download-modal';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

export const drawerWidth = 220;

const StyledUiDrawerInner = styled.div`
  height: 100%;
  margin-top: 24px;
`;
const StyledImage = styled.img`
  margin: 16px auto;
  display: block;
`;
const StyledBox = styled(Box)`
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display:none;
  }
`;

const DrowerInner = () => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const [openModal, setOpenModal] = useState(false);
  const [previewPDF, setPreviewPDF] = useState(false)

  const handleProfile = () => {
    keycloak.accountManagement()
  }

  const handleLogout = () => {
    keycloak.logout();
  };

  const handleOpenModal = () => {
    setOpenModal(true)
    setPreviewPDF(false)
  };
  const handleCloseModal = () => {
    setOpenModal(false)
    setPreviewPDF(false)
  }

  return (
    <StyledUiDrawerInner>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', height: '100%', paddingBottom: '10px' }}>
        <Box>
          <StyledImage src={netcheckLogo} alt="netcheck" width={170} />
          <ClientInfo />
        </Box>
        <StyledBox >
          <MenuItem onClick={handleProfile}><PersonOutlineIcon sx={{ marginRight: '6px' }} /> {t("Profile")}</MenuItem>
          <MenuItem onClick={handleLogout}><LogoutIcon sx={{ marginRight: '6px' }} /> {t("Logout")}</MenuItem>
        </StyledBox>
        <StyledImage style={{ marginTop: 0 }} src={chipLogo} alt="chip" width={170} />

        <ModalButton handleClick={handleOpenModal} isDark >{t('download-pdf')}<FileDownloadOutlinedIcon style={{ marginLeft: '8px', transform: 'scale(1.2)' }} /></ModalButton>

      </Box>
      <PdfDownloadModal openModal={openModal} handleCloseModal={handleCloseModal} previewPDF={previewPDF} setPreviewPDF={setPreviewPDF} />
    </StyledUiDrawerInner>
  )
}

export const UiDrawer = () => {
  const dispatch = useDispatch()
  const isDrawerOpen = useSelector(isDrawerOpenSelector);

  return (
    <Box component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders">
      <Drawer
        variant="temporary"
        open={isDrawerOpen}
        onClose={() => dispatch(globalActions.setDrawerOpen(false))}
        ModalProps={{
          keepMounted: true
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        <DrowerInner />
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        <DrowerInner />
      </Drawer>
    </Box>
  );
};

export default UiDrawer;
