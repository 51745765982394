import { Grid } from '@mui/material';
import { fetchUserData, userSelector } from '@netcheck/state/device-data';
import { metricTypeSelector, dateRangeSelector } from '@netcheck/state/global';
import { UiTextNumWidget } from '@netcheck/ui/text-num-widget';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

export const FeatureDashboardData = () => {
  const dispatch = useDispatch();
  const { data } = useSelector(userSelector);
  const metricType = useSelector(metricTypeSelector);
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(fetchUserData())
  }, [dispatch, metricType])
  return (
    <>
      <Grid item lg={3} md={6} xs={12}>
        <UiTextNumWidget text={t('Total days in the panel')} value={data.daysSinceStart} variant='large' />
      </Grid>
      <Grid item lg={3} md={6} xs={12}>
        <UiTextNumWidget text={t('Days sending data')} value={data.daysWithCollection} variant='large' />
      </Grid>
    </>
  );
};


export default FeatureDashboardData;
