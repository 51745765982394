import { createSelector } from "@reduxjs/toolkit";
import { IGlobalState } from "./global.slice";

export const globalDataSelector = (state: { global: IGlobalState }) =>
  state?.global;

export const isDrawerOpenSelector = createSelector(
  globalDataSelector,
  (state) => state?.isDrawerOpen
);

export const metricTypeSelector = createSelector(
  globalDataSelector,
  (state) => state?.metricType
);

export const operatorDataSelector = createSelector(
  globalDataSelector,
  (state) => state?.operatorData.data
)
export const dateRangeSelector = createSelector(
  globalDataSelector,
  (state) => state?.dateRange
)
export const lastDateRangeSelector = createSelector(
  globalDataSelector,
  (state) => state?.lastDateRange
)

export const deviceIdSelector = createSelector(
  operatorDataSelector,
  (state) => state.deviceId
);
