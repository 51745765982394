import styled from 'styled-components';
import Button from '@mui/material/Button';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { ITheme } from '@netcheck/utils-color-palette'

interface IStyledButton {
  theme: ITheme
  fullWidth?: boolean,
}

const StyledButton = styled(Button)`
  width: ${(props: IStyledButton) => (props.fullWidth ? '100%' : '170px')};
  height: 60px;
  margin: 7px auto;
  background-color: ${({ theme }) => theme.basicColors.BackgroundColor1};
  color: ${({ theme }) => theme.basicColors.PrimaryColor1};
  text-transform: none;
  font-weight: 600;
  font-size: 16px;
`;

interface IModalButton {
  children: React.ReactNode,
  handleClick?: () => void,
  fullWidth?: boolean,
  isDark?: boolean,
  hideIcon?: boolean
}

export const ModalButton = ({ handleClick, children, fullWidth, isDark, hideIcon }: IModalButton) => {
  return (

    <StyledButton variant="text" onClick={handleClick} fullWidth={fullWidth} style={{ backgroundColor: (isDark ? 'auto' : 'inherit'), fontWeight: (isDark ? '' : '500') }}>
      {children}
    </StyledButton>
  )
}

export default ModalButton