import { startOfDay, startOfYesterday, subDays, subMonths } from "date-fns";

import { intervalEnum, ICustomDateRange } from "@netcheck/utils/types";
import moment from 'moment';

export interface IGetInterval {
  (interval: intervalEnum): ICustomDateRange;
}

export const getInterval: IGetInterval = (interval) => {
  switch (interval) {
    case "today":
      return {
        startDate: moment(startOfDay(new Date())).format("MM.DD.yyyy"),
        endDate: moment(startOfDay(new Date())).format("MM.DD.yyyy"),
        interval: intervalEnum.today
      };
    case "yesterday":
      return {
        startDate: moment(startOfYesterday()).format("MM.DD.yyyy"),
        endDate: moment(startOfYesterday()).format("MM.DD.yyyy"),
        interval: intervalEnum.yesterday
      };
    case "last_30_days":
      return {
        startDate: moment(subDays(new Date(), 30)).format("MM.DD.yyyy"),
        endDate: moment(new Date()).format("MM.DD.yyyy"),
        interval: intervalEnum.last_30_days
      };
    case "last_3_months":
      return {
        startDate: moment(subMonths(new Date(), 3)).format("MM.DD.yyyy"),
        endDate: moment(new Date()).format("MM.DD.yyyy"),
        interval: intervalEnum.last_3_months
      };
    case "last_6_months":
      return {
        startDate: moment(subMonths(new Date(), 6)).format("MM.DD.yyyy"),
        endDate: moment(new Date()).format("MM.DD.yyyy"),
        interval: intervalEnum.last_6_months
      };
    default:
      return {
        startDate: moment(subDays(new Date(), 30)).format("MM.DD.yyyy"),
        endDate: moment(new Date()).format("MM.DD.yyyy"),
        interval: intervalEnum.last_30_days
      };
  }
};
