import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { dateRangeSelector, globalActions } from '@netcheck/state/global';
import { ICustomDateRange, intervalEnum } from '@netcheck/utils/types'
import { FilterDateInput } from './ui-date-filter-single-input';
export interface UiDateFilterInputsProps {
  isDaily?: boolean,
  handleClose?: () => void,
}

export type inputType = 'From' | 'To'

export const DateFilterInputs = ({ isDaily, handleClose }: UiDateFilterInputsProps) => {

  const dispatch = useDispatch()
  const { startDate } = useSelector(dateRangeSelector);
  const dateInput = useRef<HTMLDivElement>(null);

  const setRange = (range: ICustomDateRange) => {
    dispatch(globalActions.setDataRange(range))
    dispatch(globalActions.setLastDateRange(range))
  }

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>, inputType?: inputType) => {
    const targetValue = (e.target as HTMLInputElement).value
    const selectedValue = new Date(targetValue)
    if (e.key === "Enter") {

      e.preventDefault();
      if (targetValue) {
        let range
        if (inputType) {

          if (inputType === 'From') {
            range = {
              startDate: selectedValue,
              endDate: new Date(),
              interval: intervalEnum.custom_Interval
            }
            dateInput.current && dateInput.current.focus()

          } else {
            range = {
              startDate: startDate,
              endDate: selectedValue,
              interval: intervalEnum.custom_Interval
            }
            if (handleClose) { handleClose() }
          }
          setRange(range)

        } else {
          range = {
            startDate: selectedValue,
            endDate: selectedValue,
            interval: intervalEnum.custom_Interval
          }
          dispatch(globalActions.setDataRange(range))
        }
      }
    }
  }

  return (
    (isDaily ?
      <>
        <FilterDateInput onKeyPress={onKeyPress} inputType={'From'} />
        <FilterDateInput onKeyPress={onKeyPress} inputType={'To'} inputRef={dateInput} />
      </>
      :
      <FilterDateInput onKeyPress={onKeyPress} />
    )
  );
};


export default DateFilterInputs;
