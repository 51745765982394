import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchOperatorData, operatorDataSelector } from '@netcheck/state/global';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import InfoItem from './info-item';
import { useKeycloak } from '@react-keycloak/web';

const StyledClientInfo = styled.div`
    padding: 36px 24px 8px;
`;

export const ClientInfo = () => {
  const { keycloak, initialized } = useKeycloak()
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const { deviceId, operatorId } = useSelector(operatorDataSelector)

  useEffect(() => {
    if (!initialized) {
      return;
    }
    else{
      if(keycloak.authenticated){
        dispatch(fetchOperatorData())
      }
    }
  }, [dispatch, initialized, keycloak.authenticated ])

  const infoItems = [{
    title: t('operator'),
    logo: 'deutsche_telekom',
    info: operatorId
  },
  {
    title: t('deviceid'),
    info: deviceId
  },
  ]
  return (
    <StyledClientInfo>
      {infoItems.map((item, i) => <InfoItem key={`client-info-${i}`} {...item} />)}
    </StyledClientInfo>
  );
};


export default ClientInfo;
