import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import styled from "styled-components";
import { StyledEngineProvider } from '@mui/material/styles';
import { Theme } from "@netcheck/utils-color-palette";
import { store } from '@netcheck/state/root';

const StyledApp = styled.div`
  background: white;
`;

export interface Props {
  children: React.ReactNode
}

export const AppProviders = ({ children }: Props) => {
  return (
    <Provider store={store}>
      <Theme>
        <StyledEngineProvider injectFirst>
          <StyledApp >
            <Router>{children}</Router>
          </StyledApp>
        </StyledEngineProvider>
      </Theme>
    </Provider>
  );
};
