import { Text, View } from '@react-pdf/renderer';
import { styles } from '@netcheck/feature/preview-pdf'
import { useTranslation } from 'react-i18next';

export const RaportDescription = () => {
  const { t } = useTranslation()

  return (
    <View style={styles.box}>

      <Text style={styles.fontSection}>
        <Text style={{ ...styles.textBoldDescription }}>
          {t('Explanation on terminology')}
        </Text>
      </Text>
      <Text style={styles.fontSection}>
        <Text style={styles.textBold}>
          {t('downloadTooltipTitle')}:&nbsp;
        </Text>
        <Text style={styles.text}>
          {t('downloadTooltip')}
        </Text>
      </Text>

      <Text style={styles.fontSection}>
        <Text style={styles.textBold}>
          {t('DNSTooltipTitle')}:&nbsp;

        </Text>
        <Text style={styles.text}>
          {t('DNSTooltip')}
        </Text>
      </Text>

      <Text style={styles.fontSection}>
        <Text style={styles.textBold}>
          {t('RTTTooltipTitle')}:&nbsp;
        </Text>
        <Text style={styles.text}>
          {t('RTTTooltip')}
        </Text>
      </Text>

      <Text style={styles.fontSection}>
        <Text style={styles.textBold}>
          {t('JitterTooltipTitle')}:&nbsp;
        </Text>
        <Text style={styles.text}>
          {t('JitterTooltip')}
        </Text>
      </Text>

      <Text style={styles.fontSection}>
        <Text style={styles.textBold}>
          {t('PacketLossTooltipTitle')}:&nbsp;
        </Text>
        <Text style={styles.text}>
          {t('PacketLossTooltip')}
        </Text>
      </Text>

    </View>
  )
}

export default RaportDescription