import { useTranslation } from "react-i18next";
import { Box, Stack } from "@mui/material";
import { RadioComponent } from "@netcheck/ui/view-mode-radio";
import { DateRangeWidget } from "@netcheck/ui/date-range-widget";
import { DatePicker } from "@netcheck/ui/date-picker";
import { dateRangeSelector, metricTypeSelector } from "@netcheck/state/global";
import { useSelector } from "react-redux";
import { RadioValueType } from "@netcheck/utils/types";
import { ModalButton } from "@netcheck/ui-modal-button";
import { StyledModal, StyledTitleTypography, ErrorTypography } from "./styles";
import { useEffect, useRef, useState } from "react";
import { FeaturePreviewPDF } from "@netcheck/feature/preview-pdf";
import {
  CircularProgressCustom,
  CircularProgressWithLabel,
} from "@netcheck/ui/pdf-raport-progress-bar";
import moment from "moment";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const style = {
  top: "0",
  left: "50%",
  transform: "translate(-50%, 0%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  mt: 4,
  borderRadius: 3,
};
interface IPdfDownloadModal {
  openModal: boolean;
  handleCloseModal: () => void;
  previewPDF: boolean;
  setPreviewPDF: (arg: boolean) => void;
}

export const PdfDownloadModal = ({
  openModal,
  handleCloseModal,
  previewPDF,
  setPreviewPDF,
}: IPdfDownloadModal) => {
  const { t } = useTranslation();
  const metricType = useSelector(metricTypeSelector);
  const { startDate, endDate } = useSelector(dateRangeSelector);

  const downloadRef = useRef(null);

  const [error, setError] = useState({
    interval: "",
    startEnd: "",
  });

  const [numOfGeneratedGraphs, setNumOfGeneratedGraphs] = useState(0);
  const [documentReady, setDocumentReady] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);

  const a = moment(startDate, "MM-DD-yyyy");
  const b = moment(endDate, "MM-DD-yyyy");
  const numOfDays = b.diff(a, "days");

  useEffect(() => {
    setError({
      interval: "",
      startEnd: "",
    });
  }, [metricType]);

  const handleGeneratePDFPreview = () => {
    setError({
      interval: "",
      startEnd: "",
    });
    if (numOfDays > 30) {
      setError((prevError) => ({
        ...prevError,
        interval: t("Max raport interval is 30 days"),
      }));
      return;
    } else if (
      !startDate ||
      !endDate ||
      startDate === "Invalid date" ||
      endDate === "Invalid date"
    ) {
      setError((prevError) => ({
        ...prevError,
        startEnd: t("Select start and end date"),
      }));
      return;
    } else {
      setError({
        interval: "",
        startEnd: "",
      });
    }
    setPreviewPDF(true);
    setDocumentReady(false);
    setIsGenerating(true);
  };

  const handleClose = () => {
    setError({
      interval: "",
      startEnd: "",
    });
    handleCloseModal();
    setNumOfGeneratedGraphs(0);
    setDocumentReady(false);
    setIsGenerating(false);
  };

  return (
    <StyledModal
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} position={"absolute"}>
        <div style={{ margin: "auto 16px" }}>
          <StyledTitleTypography
            id="modal-modal-title"
            variant="h6"
            textAlign={"center"}
          >
            {t("Generate raport PDF")}
          </StyledTitleTypography>

          <RadioComponent
            reversed={true}
            isGenerating={isGenerating}
            setDocumentReady={setDocumentReady}
            setPreviewPDF={setPreviewPDF}
          />
          <DateRangeWidget />
          <DatePicker
            setPreviewPDF={setPreviewPDF}
            setDocumentReady={setDocumentReady}
            isGenerating={isGenerating}
            isDaily={metricType === RadioValueType.daily_view}
            inModal={true}
          />

          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            style={{}}
          >
            <ErrorTypography>
              {error.interval}
              {error.startEnd}&nbsp;
            </ErrorTypography>

            {!isGenerating && !documentReady && (
              <ModalButton
                fullWidth
                isDark
                handleClick={handleGeneratePDFPreview}
              >
                <>{t("Generate PDF")} </>
              </ModalButton>
            )}

            {isGenerating && !documentReady && (
              <ModalButton fullWidth isDark>
                {numOfGeneratedGraphs / (numOfDays + 1) === 1 ? (
                  <>
                    {t("Download link preparing")} &nbsp;&nbsp;&nbsp;
                    <CircularProgressCustom />
                  </>
                ) : (
                  <>
                    {t("Generating PDF")} &nbsp;&nbsp;&nbsp;
                    <CircularProgressWithLabel
                      style={{ width: "50px", height: "50px" }}
                      value={(numOfGeneratedGraphs / (numOfDays + 1)) * 100}
                    />
                  </>
                )}
              </ModalButton>
            )}
            {documentReady && (
              <ModalButton
                fullWidth
                isDark
                handleClick={() => {
                  if (downloadRef && downloadRef.current) {
                    //@ts-ignore
                    downloadRef?.current?.click();
                    setIsGenerating(false);
                  }
                }}
              >
                {" "}
                <>
                  {t("Download")}{" "}
                  <FileDownloadOutlinedIcon
                    style={{ marginLeft: "8px", transform: "scale(1.2)" }}
                  />
                </>
              </ModalButton>
            )}

            <ModalButton fullWidth hideIcon handleClick={handleClose}>
              {t("Cancel")}
            </ModalButton>
          </Stack>
        </div>

        {previewPDF && (
          <FeaturePreviewPDF
            setIsGenerating={setIsGenerating}
            setPreviewPDF={setPreviewPDF}
            setNumOfGeneratedGraphs={setNumOfGeneratedGraphs}
            setDocumentReady={setDocumentReady}
            downloadRef={downloadRef}
          />
        )}
      </Box>
    </StyledModal>
  );
};

export default PdfDownloadModal;
