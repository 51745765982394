import { useTheme } from "styled-components";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import { ITheme } from "@netcheck/utils-color-palette";
import { IEntry } from "@netcheck/state/device-data";
import moment from "moment";
import { head, omit } from "lodash";
import { useSelector } from "react-redux";
import { metricTypeSelector } from "@netcheck/state/global";
import {
  StyledLegend,
  StyledListItem,
  StyledBenchmark,
  StyledLine,
  StyledUiLineChart,
} from "./styles";
import { useTranslation } from "react-i18next";
interface ILineChart {
  color: string;
  value: string;
}

export const UiLineChart = ({
  entries,
  benchmark,
  isPDF
}: {
  entries: IEntry[];
  benchmark?: number;
  isPDF?: boolean
}) => {
  const theme = useTheme() as ITheme;
  const metricType = useSelector(metricTypeSelector);

  const { t } = useTranslation();

  const newEntries = entries.map((el) =>
    el.dayHour ? { ...el, dayDate: el.dayDate + ":" + el.dayHour } : el
  );

  const formatDate = (d: string) => {

    // if (manualMetric === 'Hour') {
    //   return moment(d, "YYYY-MM-DD:HH:mm:ssZ").format("lll")
    // }

    return `${metricType === "Hour"
      ? moment(d, "YYYY-MM-DD:HH:mm:ssZ").format("lll")
      : moment(d, "YYYY-MM-DD:HH:mm:ssZ").format("ll")
      }`;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderLegend = ({ payload }: any) => {
    return (
      <StyledLegend>
        {payload.map((entry: ILineChart, index: number) => (
          <StyledListItem color={entry.color} key={`item-${index}`}>
            <span>{t(`${entry.value}`)}</span>
          </StyledListItem>
        ))}
        {benchmark ? (
          <StyledBenchmark>
            <StyledLine />
            <span>{t("expected results")}</span>
          </StyledBenchmark>
        ) : (
          ""
        )}
      </StyledLegend>
    );
  };
  const lineEntries = omit(head(newEntries), ["dayDate", "dayHour", "url"]);
  return (
    <StyledUiLineChart style={{ boxShadow: (isPDF ? "0px" : "0px 3px 40px rgb(0 0 0 / 8%)") }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={newEntries}
          margin={{
            top: 50,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid
            vertical={false}
            strokeDasharray="10"
            strokeWidth={0.8}
            stroke="#d9d9d9"
          />
          <XAxis
            tickFormatter={formatDate}
            axisLine={false}
            tickCount={3}
            dataKey="dayDate"
            fontSize="12"
            tickLine={false}
            tickMargin={12}
          />
          <YAxis
            yAxisId="1"
            axisLine={false}
            tickCount={3}
            fontSize="11"
            tickLine={false}
            tickMargin={12}
          />
          <YAxis
            yAxisId="2"
            dataKey={"jitter"}
            orientation="right"
            axisLine={false}
            tickCount={3}
            fontSize="11"
            tickLine={false}
            tickMargin={12}
          />
          <Tooltip
            labelFormatter={formatDate}
            formatter={(num: string) => parseFloat(num).toFixed(2)}
          />
          <Legend
            iconSize={10}
            iconType="circle"
            wrapperStyle={{ top: -14, left: 15 }}
            content={renderLegend}
          />
          {benchmark && (
            <ReferenceLine
              
              ifOverflow="extendDomain"
              yAxisId="1"
              y={benchmark}
              strokeDasharray="5 12"
              stroke={theme.basicColors.PrimaryColor2}
            />
          )}
          {Object.keys(lineEntries).map((l, i) => {
            return (
              <Line
                isAnimationActive={isPDF ? false : true}
                yAxisId={l === "jitter" ? "2" : "1"}
                type="monotone"
                dataKey={l}
                key={l}
                stroke={theme.chartsColors["ResultColor" + i]}
                dot={false}
              />
            );
          })}
        </LineChart>
      </ResponsiveContainer>
    </StyledUiLineChart>
  );
};

export default UiLineChart;
