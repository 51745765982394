import styled from 'styled-components';
import { TextDiv, ValueDiv, TypeVariant } from './divComponents'
import { useTranslation } from 'react-i18next';

export interface UiTextNumWidgetProps {
  text: string,
  variant: TypeVariant,
  value?: number,
  isPDF?: boolean
}

const StyledWidget = styled.div.attrs((props: UiTextNumWidgetProps) => props)`
  height: ${({ variant }) => variant === 'small' ? '35px' : '50px'};
  @media (min-width: 600px) and (max-width: 700px) {
    height: ${({ variant }) => variant === 'small' ? '35px' : '80px'};
  }
  color: ${(props) => props.theme.basicColors.PrimaryColor1};
  background-color: ${(props) => props.theme.basicColors.BackgroundColor2};
  
  border-radius: 4px;
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
`;

export function UiTextNumWidget({ text, value, variant, isPDF }: UiTextNumWidgetProps) {
  const { t } = useTranslation()

  return (
    <StyledWidget text={text} value={value} variant={variant} style={{ boxShadow: (isPDF ? "0px" : "0px 3px 40px rgba(0, 0, 0, 0.08)"), marginBottom: '8px' }}>
      <TextDiv variant={variant}>{`${t(text)}`}</TextDiv>
      <ValueDiv style={{ paddingLeft: "10px" }} variant={variant}>{value}</ValueDiv>
    </StyledWidget>
  );
};

export default UiTextNumWidget;
