import { createSelector } from "@reduxjs/toolkit";
import { IDeviceDataState } from "./device-data.slice";

export const deviceDataSelector = (state: { deviceData: IDeviceDataState }) =>
  state?.deviceData;

export const metricsSelector = createSelector(
  deviceDataSelector,
  (state) => state?.metrics
);

export const downloadMetricSelector = createSelector(
  metricsSelector,
  (state) => state?.download
);
export const uploadMetricSelector = createSelector(
  metricsSelector,
  (state) => state?.upload
);
export const twampMetricSelector = createSelector(
  metricsSelector,
  (state) => state?.twamp
);
export const dnsMetricSelector = createSelector(
  metricsSelector,
  (state) => state?.dns
);
export const userSelector = createSelector(
  deviceDataSelector,
  (state) => state.user
);
export const totalDataSelector = createSelector(
  deviceDataSelector,
  (state) => state.total
);
