import { Stack, TextField } from '@mui/material'
import styled from 'styled-components'


export const StyledStack = styled(Stack)`
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;    
`

export const StyledInputLabel = styled.span`
  font-weight: 500;
  font-size: 0.875rem;
  text-transform: lowercase;
  &:first-letter{
    text-transform: uppercase;
  }
`

export const StyledTextField = styled(TextField)`
  width: 170px;
  margin-right: 20px;
`

