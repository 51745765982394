import { ReactKeycloakProvider } from '@react-keycloak/web';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';
import App from './app/app';
import { AppProviders } from './appProviders'
import './i18n';
import { keycloak } from '@netcheck/utils/api';

ReactDOM.render(
  <StrictMode>
    <ReactKeycloakProvider authClient={keycloak}>
      <AppProviders>
        <App />
      </AppProviders>
    </ReactKeycloakProvider>
  </StrictMode>,
  document.getElementById('root')
);
