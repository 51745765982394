import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";
import { MetricType, Status } from "@netcheck/utils/types";
import { api } from "@netcheck/utils/api";
import { MetricInterval } from "@netcheck/utils/types";
import { IState } from "@netcheck/state/root";
import moment from "moment";

export const DEVICEDATA_FEATURE_KEY = "deviceData";

export interface DeviceDataEntity {
  id: number;
}
export interface IEntry {
  [key: string]: Date | string | number;
}

export interface IMetricData {
  type?: string;
  entries?: IEntry[] | null;
  avg?: number;
  max?: number;
  expected?: number;
}
export const deviceDataAdapter = createEntityAdapter<DeviceDataEntity>();
export interface IDeviceDataState extends EntityState<DeviceDataEntity> {
  metrics: { [P in MetricType]: IMetricData | null };
  user: {
    loadingStatus: Status;
    data: {
      daysSinceStart: number;
      daysWithCollection: number;
    };
  };
  total: any | null;
  error: string | null;
}

export const initialIDeviceDataState: any = deviceDataAdapter.getInitialState({
  metrics: {
    dns: null,
    download: null,
    twamp: null,
    upload: null,
  },
  user: {
    loadingStatus: Status.NEW,
    data: {
      daysSinceStart: 0,
      daysWithCollection: 0,
    },
  },
  total: null,
  error: null,
});

export const fetchDeviceData = createAsyncThunk(
  "deviceData/fetchDeviceData",
  async (metric: MetricType, { getState }) => {
    const { global } = getState() as IState;
    const metricType = global.metricType as MetricInterval;
    const {
      dateRange: { startDate, endDate },
    } = global;

    try {
      let res;
      if (metric && metricType) {
        res = await api.get(
          `/devices/${
            global.operatorData.data.deviceId
          }/metrics/${metric}${metricType}?from=${moment(
            startDate,
            "MM-DD-yyyy"
          ).format("yyyy-MM-DD")}&to=${moment(endDate, "MM-DD-yyyy").format(
            "yyyy-MM-DD"
          )} `
        );
      } else {
        res = await api.get(
          `/devices/${global.operatorData.data.deviceId}/metrics?from=${moment(
            startDate,
            "MM-DD-yyyy"
          ).format("yyyy-MM-DD")}&to=${moment(endDate, "MM-DD-yyyy").format(
            "yyyy-MM-DD"
          )} `
        );
      }
      return res.data;
    } catch (error) {
      return {};
    }
  }
);
export const fetchUserData = createAsyncThunk(
  "deviceData/fetchUserData",
  async (_, { getState }) => {
    const { global } = getState() as IState;
    try {
      const res = await api.get(
        `/devices/${global.operatorData.data.deviceId}/metrics/user`
      );
      return res.data;
    } catch (error) {
      return error;
    }
  }
);

export const deviceDataSlice = createSlice({
  name: DEVICEDATA_FEATURE_KEY,
  initialState: initialIDeviceDataState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<IDeviceDataState>) => {
    builder
      .addCase(fetchDeviceData.fulfilled, (state: IDeviceDataState, action) => {
        if (action.meta.arg) {
          state.metrics[action.meta.arg] =
            action.payload?.response?.status !== 404 ? action.payload : {};
        } else {
          state.total =
            action.payload?.response?.status !== 404 ? action.payload : {};
        }
      })
      .addCase(fetchDeviceData.rejected, (state: IDeviceDataState, action) => {
        state.error = action.error.message || "";
      })
      .addCase(fetchUserData.fulfilled, (state: IDeviceDataState, action) => {
        state.user.data = action.payload;
      })
      .addCase(fetchUserData.rejected, (state: IDeviceDataState, action) => {
        state.error = action.error.message || "";
      });
  },
});

export const deviceDataReducer = deviceDataSlice.reducer;
export const deviceDataActions = deviceDataSlice.actions;
