import { intervalEnum } from '@netcheck/utils/types'
import PredefinedInterval from './ui-date-filter-interval-option';
interface IPredefinedValues {
  isDaily?: boolean
}
interface IPredefinedValueArr {
  daily: intervalEnum[],
  hourly: intervalEnum[]
}

export const PredefinedValues = ({ isDaily }: IPredefinedValues) => {
  const predefinedValueArr: IPredefinedValueArr = {
    daily: [intervalEnum.today, intervalEnum.yesterday, intervalEnum.last_30_days, intervalEnum.last_3_months, intervalEnum.last_6_months],
    hourly: [intervalEnum.today, intervalEnum.yesterday]
  }

  return (
    <>{predefinedValueArr[(isDaily ? 'daily' : 'hourly')].map((el, i) => <PredefinedInterval el={el} isDaily={isDaily} key={i} />)}</>
  );
};

export default PredefinedValues;
