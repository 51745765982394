import { UiStatisticsBox } from '@netcheck/ui/statistics-box';
import { SyncAlt } from '@mui/icons-material';
import { twampMetricSelector, fetchDeviceData } from '@netcheck/state/device-data';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { metricTypeSelector, dateRangeSelector } from '@netcheck/state/global';
import { removeValuesFromEntries } from '@netcheck/utils/functions';

const keysToOmit = ['lossRatio']

interface IFeatureTwamppRtt {
  dataProps?: any,
  isPDF?: boolean
}

export const FeatureTwampRtt = ({ dataProps, isPDF }: IFeatureTwamppRtt) => {
  const dispatch = useDispatch();
  let data = useSelector(twampMetricSelector);
  if (dataProps) {
    data = dataProps
  }
  const metricType = useSelector(metricTypeSelector);
  const { startDate, endDate } = useSelector(dateRangeSelector)
  const { t } = useTranslation()

  useEffect(() => {
    if (!dataProps) {
      if (startDate === 'Invalid date' || endDate === 'Invalid date') return
      dispatch(fetchDeviceData('twamp'))
    }
  }, [dispatch, metricType, startDate, endDate])
  const filteredData = removeValuesFromEntries(data, keysToOmit)

  return filteredData && (
    <div>
      <UiStatisticsBox icon={<SyncAlt />} title={t("TWAMP RTT and Jitter, ms")} data={filteredData} tooltipText={`${t("RTTTooltipTitle")}: ${t("RTTTooltip")}. ${t("JitterTooltipTitle")}: ${t("JitterTooltip")}`} isPDF={isPDF ? true : false} />
    </div>
  );
};

export default FeatureTwampRtt;
