import DateRangeIcon from "@mui/icons-material/DateRange";
import { isEqual } from "date-fns";
import { StyledSpan, StyledSpanBold, StyledButton } from "./styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { dateRangeSelector } from "@netcheck/state/global";
import moment from "moment";

interface IButtonComponent {
  handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const ButtonComponent = ({ handleClick }: IButtonComponent) => {
  const { t } = useTranslation();
  const { startDate, endDate, interval } = useSelector(dateRangeSelector);
  return (
    <StyledButton onClick={handleClick}>
      <StyledSpan>{t("Date range")}</StyledSpan>
      &nbsp;{" "}
      <DateRangeIcon
        style={{ width: "20px", marginRight: "10px", marginLeft: "10px" }}
      />
      <StyledSpanBold>
        {interval?.split("_").join(" ") !== "custom Interval" && t(interval)}
      </StyledSpanBold>
      <StyledSpanBold style={{ marginLeft: "10px" }}>
        {startDate &&
          ` ${moment(startDate, "MM-DD-yyyy").format("DD.MM.yyyy")} `}
        {endDate &&
          startDate &&
          !moment(startDate, "MM-DD-yyyy").isSame(endDate) &&
          `- ${moment(endDate, "MM-DD-yyyy").format("DD.MM.yyyy")}`}
      </StyledSpanBold>
    </StyledButton>
  );
};
