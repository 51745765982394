import styled from 'styled-components';
import { Grid } from '@mui/material';
import { StyleSheet } from '@react-pdf/renderer';

export const StyledDashboard = styled.div`
  background-color: ${(props) => props.theme.basicColors.BackgroundColor1};   
  padding-top: 66px;
`;
export const StyledGrid = styled(Grid)`
  padding: 25px 40px;
  @media (max-width: 450px) { 
  padding: 25px 10px;
  }
`
export const GraphGridContainer = styled(Grid)`
  padding-top: 10px !important;
  padding: 10px 10px 0px 15px;
`
export const FlexGrid = styled(Grid)`
  @media (max-width: 1249px) and (min-width: 1200px) { 
  *{
    font-size: 14px;
  }
  h1{
    font-size:14px;
  }
  svg{
    font-size: 1.3rem
  }
  }
@media (max-width: 1360px) and (min-width: 1250px) { 
  *{
    font-size: 14px;
  }
  h1{
    font-size:16px;
  }
  svg{
    font-size: 1.3rem
  }
  }
`
export const StyledFeaturePreviewPDF = styled.div`
  width: 1050px;
  margin-top: 5rem;
`;
export const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 10,
    paddingHorizontal: 25,
  },

  page: {
    flexDirection: 'column',
    margin: '20 40'
  },
  headerLogo: {
    width: 130,
    marginBottom: 20,
    textAlign: 'justify',
    marginHorizontal: 'auto',
  },
  headerText: {
    fontWeight: 'bold',
    marginHorizontal: 'auto',
  },
  headerTable: {
    marginVertical: 20
  },
  pageLogo: {
    position: 'absolute',
    width: 80,
    right: 25,
    top: 0,
  },
  pageLogoFooter: {
    position: 'absolute',
    width: 80,
    right: 0,
    top: 0,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 0,
  },
  box: {
    display: 'flex',
  },
  text: {
    fontFamily: 'Times-Roman',
    textAlign: 'justify',
    fontSize: 12

  },
  textBold: {
    fontWeight: 'bold',
    color: 'black',
    fontSize: 12,
  },
  textBoldDescription: {
    fontWeight: 'bold',
    fontSize: 14,
  },
  fontSection: {
    marginTop: 20,
  },
  imageChart: {
    marginHorizontal: "auto",
    height: 170,
    width: 480
  },
  imageChartWithWidget: {
    marginHorizontal: "auto",
    height: 205,
    width: 480
  },
  pageTitle: {
    marginLeft: 30,
    marginBottom: 0,
    fontWeight: 'bold',
    fontSize: 14
  },
  link: {
    color: 'blue'
  }
});