import React, { useState } from 'react'
import { Grid } from '@mui/material';
import { ButtonComponent as Button } from './ButtonComponent'
import { RadioComponent as Radio } from '@netcheck/ui/view-mode-radio'
import { DatePickerContainer } from '@netcheck/ui/date-picker'
import { RadioGrid, StyledGrid, StyledUiDateFilter } from './styles';


export function UiDateFilter() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const datePickerContainerProps = {
    anchorEl,
    handleClose
  }

  return (
    <StyledUiDateFilter>
      <Grid container spacing={2} sx={{ display: 'flex', flexDirection: 'row-reverse' }} >
        <Grid container spacing={2} sx={{ display: 'flex', flexDirection: 'row-reverse', ml: 0 }} >

          <StyledGrid item md={9} xs={12} order={{ md: 1, xs: 2 }} >
            <Button handleClick={handleClick} />
          </StyledGrid>
          <RadioGrid item md={3} xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }} order={{ md: 2, xs: 1 }}>
            <Radio />
          </RadioGrid>
        </Grid>

        <Grid spacing={2} container sx={{ position: 'relative' }}>
          <Grid item xs={12} sx={{ position: 'absolute', top: 0, left: '25px' }} >
            <DatePickerContainer {...datePickerContainerProps} />
          </Grid>
        </Grid>

      </Grid>
    </StyledUiDateFilter>
  );
};

export default UiDateFilter;
