export type StatsItem = {
  title: string;
  amount: number;
};

export type MetricInterval = "Daily" | "Hour";

export type intervalType =
  | "today"
  | "yesterday"
  | "last30days"
  | "last3months"
  | "last6months"
  | "customInterval";

export enum intervalEnum {
  today = "today",
  yesterday = "yesterday",
  last_30_days = "last_30_days",
  last_3_months = "last_3_months",
  last_6_months = "last_6_months",
  custom_Interval = "custom_Interval"
}

export interface ICustomDateRange {
  startDate: Date | string;
  endDate: Date | string;
  interval: intervalEnum;
}

export enum RadioValueType {
  daily_view = "Daily",
  hourly_view = "Hour"
}

export enum Status {
  NEW = "NEW",
  ONGOING = "ONGOING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR"
}

export type MetricType = "dns" | "download" | "twamp" | "upload" | "";
