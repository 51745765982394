import styled from 'styled-components'
import { Button } from '@mui/material'

export const StyledButton = styled(Button)`
  color: ${(props) => props.theme.basicColors.PrimaryColor1};
  background-color: ${(props) => props.theme.basicColors.BackgroundColor2};
  border-radius: ${(props) => props.theme.border.Radius};;
  width:100%;
  margin-left: 0;
  display:flex;
  justify-content: flex-start;
  font-size:14px;
  font-weight: 600;
  line-height:1;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) { 
    margin-top: 11px;
    padding: 16px 20px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) { 
    padding: 16px 10px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    height: 100%;
    margin:0;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-left: 16px;
  }
`

export const StyledInputLabel = styled.span`
  font-weight: 500;
  font-size: 0.875rem;
  text-transform: lowercase;
  font-weight: 500;
  &:first-letter{
    text-transform: uppercase;
  }
`

