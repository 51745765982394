import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Box } from '@mui/material';

export const StylingDiv = styled.div`
.Mui-selected.MuiPickersDay-dayWithMargin{
  background-color: ${(props) => props.theme.basicColors.PrimaryColor3};
}
  .staticDateRangePicker>div{
    @media (max-width: ${({ theme }) => theme.breakpoints.md}) { 
        flex-direction: column;
    }
  }
.MuiPickersDay-root.Mui-selected.MuiDateRangePickerDay-day{
  background-color: ${(props) => props.theme.basicColors.PrimaryColor3};
}
.MuiDateRangePickerDay-rangeIntervalDayHighlight{
  background-color: ${(props) => props.theme.basicColors.PrimaryColor5};   
}
`

export const StyledBox = styled(Box)`
  width: 280px;  
	padding-top: 10px;
`

