import { UiStatisticsBox } from '@netcheck/ui/statistics-box';
import { Cases, QueryBuilder } from '@mui/icons-material';
import { dnsMetricSelector, fetchDeviceData, IEntry, IMetricData } from '@netcheck/state/device-data';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { metricTypeSelector, dateRangeSelector } from '@netcheck/state/global';

interface IFeatureDnsResolveTime {
  dataProps?: any,
  isPDF?: boolean
}

export const FeatureDnsResolveTime = ({ dataProps, isPDF }: IFeatureDnsResolveTime) => {
  const dispatch = useDispatch();
  let data = useSelector(dnsMetricSelector)
  const metricType = useSelector(metricTypeSelector);
  const { startDate, endDate } = useSelector(dateRangeSelector)
  const { t } = useTranslation();

  if (dataProps) {
    data = dataProps
  }

  const newEntries = data?.entries?.map((day) => {
    if (!Array.isArray(day.data)) return day

    let newData = { ...day }
    for (const el of day.data) newData = { ...newData, [el.url]: el.resolveTime }

    delete newData.data
    return newData
  })

  const processedData = { ...data, entries: newEntries }

  useEffect(() => {
    if (!dataProps) {
      if (startDate === 'Invalid date' || endDate === 'Invalid date') return
      dispatch(fetchDeviceData('dns'))
    }
  }, [dispatch, metricType, startDate, endDate])

  return processedData && (
    <div>
      <UiStatisticsBox icon={<QueryBuilder />} title={t("DNS Resolve Time, ms")} data={processedData} tooltipText={`${t("DNSTooltipTitle")}: ${t("DNSTooltip")}`} isPDF={isPDF ? true : false} />
    </div>
  );
};

export default FeatureDnsResolveTime;
