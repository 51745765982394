import styled from 'styled-components';
import { Grid, SvgIconProps, Typography } from '@mui/material';
import { ReactElement, useState } from 'react';
import { UiTextNumWidget } from '@netcheck/ui/text-num-widget';
import { UiLineChart } from '@netcheck/ui/line-chart';
import { StatsItem } from '@netcheck/utils/types';
import { IMetricData } from '@netcheck/state/device-data';
import { getFormattedAmount } from '@netcheck/utils/functions';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';

export interface UiStatisticsBoxProps {
  title?: string;
  icon?: ReactElement<SvgIconProps>;
  stats?: StatsItem[];
  data?: IMetricData;
  tooltipText?: string;
  isPDF?: boolean
}

const StyledUiStatisticsBox = styled.div`
  margin: 24px 0 8px 6px;
  height: 100%;
`;
const StyledTitle = styled.h2`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  margin-bottom: 18px;
  /* margin-left:8px; */
  font-size: 18px;
`;
const StyledBox = styled(Box)`
  background-color: white;
  margin-top: 12px;
  width: 100%;
  height: 290px;
  border-radius: 4px;
  padding: 18px 8px 0;

  display: flex;
  justify-content: center;
  align-items: center;
}
`;
const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)`
  box-sizing: content-box;
  padding: 5px;
  height: 12px;
  width: 12px;
  margin-top: -12px;
  margin-left: 3px;
  color: ${(props) => props.theme.basicColors.PrimaryColor2};
}
`;

export const UiStatisticsBox = ({ title, icon, stats, data, tooltipText, isPDF }: UiStatisticsBoxProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation()

  const handleOpen = () => {
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <StyledUiStatisticsBox >
      <StyledTitle>{icon}
        {title}
        {!isPDF && (tooltipText && <Tooltip open={open} onClose={handleClose} onOpen={handleOpen} title={tooltipText} >
          <StyledInfoOutlinedIcon onFocus={handleOpen} onBlur={handleClose} />
        </Tooltip>)}

      </StyledTitle>
      {stats && <Grid container spacing={2}>
        {stats.map((stat: StatsItem, id) => (
          <Grid key={id} item sm={12 / stats.length} xs={12}>
            {data?.entries ? <UiTextNumWidget isPDF={isPDF ? true : false} text={stat.title} value={stat.amount} variant='large' /> :
              <UiTextNumWidget isPDF={isPDF ? true : false} text={'No data available'} variant='large' />}
          </Grid>
        ))}
      </Grid>}

      {data?.entries && <UiLineChart entries={data.entries} benchmark={getFormattedAmount(data?.expected)} isPDF={isPDF ? true : false} />}
      {!data?.entries &&
        <StyledBox style={{ boxShadow: (isPDF ? "0px" : "0px 3px 40px rgb(0 0 0 / 8%)") }} ><p style={{ marginBottom: '40px' }}>{`${t('No data available')}`}</p> </StyledBox>
      }
    </StyledUiStatisticsBox>
  );
};

export default UiStatisticsBox;
