import { UiStatisticsBox } from '@netcheck/ui/statistics-box';
import { UploadOutlined } from '@mui/icons-material';
import { StatsItem } from '@netcheck/utils/types';
import { useEffect } from 'react';
import { uploadMetricSelector, fetchDeviceData } from '@netcheck/state/device-data';
import { getFormattedAmount } from '@netcheck/utils/functions';
import { useDispatch, useSelector } from 'react-redux';
import { metricTypeSelector, dateRangeSelector } from '@netcheck/state/global';
import { useTranslation } from 'react-i18next';

interface IFeatureAverageUpload {
  statsProps?: any,
  dataProps?: any,
  isPDF?: boolean
}

export const FeatureAverageUpload = ({ statsProps, dataProps, isPDF }: IFeatureAverageUpload) => {
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const data = useSelector(uploadMetricSelector);
  const metricType = useSelector(metricTypeSelector);
  const { startDate, endDate } = useSelector(dateRangeSelector)
  const stats: StatsItem[] = [
    {
      title: 'Max. Upload Bitrate, Mbps',
      amount: getFormattedAmount(data?.max)
    },
    {
      title: 'Avg. of Bitrate, Mbps',
      amount: getFormattedAmount(data?.avg)
    }
  ]
  useEffect(() => {

    if (!dataProps) {
      if (startDate === 'Invalid date' || endDate === 'Invalid date') return
      dispatch(fetchDeviceData('upload'))
    }
  }, [dispatch, metricType, startDate, endDate])

  return data && (
    <div>
      <UiStatisticsBox icon={<UploadOutlined />} title={`${t('Average Upload Bitrate per Day, Mbps')}`} tooltipText={`${t("downloadTooltipTitle")}: ${t("downloadTooltip")}`} stats={statsProps ? statsProps : stats} data={dataProps ? dataProps : data} isPDF={isPDF ? true : false} />
    </div>
  );

};


export default FeatureAverageUpload;
