import React, { KeyboardEvent, Ref } from 'react'
import { useTranslation } from 'react-i18next'
import { inputType } from './ui-date-filter-inputs'
import { StyledInputLabel, StyledStack, StyledTextField } from './styles'

interface IFilterDateInput {
    onKeyPress: (e: KeyboardEvent<HTMLInputElement>, inputType?: inputType) => void,
    inputType?: inputType,
    inputRef?: Ref<HTMLDivElement> | undefined
}

export const FilterDateInput = ({ onKeyPress, inputType, inputRef }: IFilterDateInput) => {
    const { t } = useTranslation()

    return (
        <StyledStack direction='row' >
            <StyledInputLabel>{t((inputType ? inputType : 'Date'))}</StyledInputLabel>
            <StyledTextField inputRef={(inputRef ? inputRef : null)} type="date" onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => onKeyPress(e, inputType)} size="small" />
        </StyledStack>
    )
}

export default FilterDateInput