import { UiStatisticsBox } from '@netcheck/ui/statistics-box';
import { WarningAmber } from '@mui/icons-material';
import { twampMetricSelector } from '@netcheck/state/device-data';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { pickValuesFromEntries } from '@netcheck/utils/functions';

interface IFeaturePacketLoss {
  dataProps?: any,
  isPDF?: boolean
}
const keysToPick = ['dayDate','lossRatio'];
export const FeaturePacketLoss = ({ dataProps, isPDF }: IFeaturePacketLoss) => {
  let data = useSelector(twampMetricSelector);
  if (dataProps) {
    data = dataProps
  }
  const { t } = useTranslation();
  const filteredData = pickValuesFromEntries(data, keysToPick);
  return filteredData && (
    <div>
      <UiStatisticsBox icon={<WarningAmber />} title={t("Packet Loss %")} data={filteredData} tooltipText={`${t("PacketLossTooltipTitle")}: ${t("PacketLossTooltip")}`} isPDF={isPDF ? true : false} />
    </div>
  );
};

export default FeaturePacketLoss;
