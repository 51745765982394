import { IMetricData, IEntry } from "@netcheck/state/device-data"
import { omit, pick } from "lodash"

const getFormattedAmount = (num: number | undefined): number => {
  return num ? parseFloat(num.toFixed(2)) : 0
}

const removeValuesFromEntries = (data: IMetricData | null, keys: string[]) => {
  return data ? { ...data, entries: data?.entries?.map((d: IEntry) => { return omit(d, keys) }) } : null
}

const pickValuesFromEntries = (data: IMetricData | null, keys: string[]) => {
  return data ? { ...data, entries: data?.entries?.map((d: IEntry) => { return pick(d, keys) }) } : null
}

export { getFormattedAmount, removeValuesFromEntries, pickValuesFromEntries }
