import styled from 'styled-components';
import { Modal, Typography } from '@mui/material';

export const StyledModal = styled(Modal)`
  /* overflow: scroll; */
`;

export const StyledTitleTypography = styled(Typography)`
  font-weight: 600;
  margin: 10px auto;
`;

export const ErrorTypography = styled.p`
font-size: 13px;
color: red;
align-self: middle;
margin: 0;
`
