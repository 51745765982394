import {
  Document,
  Page,
  PDFDownloadLink,
  Text,
  Image,
  View,
} from "@react-pdf/renderer";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { RaportDescription } from "@netcheck/ui/pdf-raport-description";
import { styles } from "@netcheck/feature/preview-pdf";
import { GraphsContainer } from "@netcheck/ui/pdf-raport-graphs-container";
import netcheckLogo from "apps/probe-fe/src/assets/logos/netcheck-logo.png";

interface IDownloadLink {
  headerTableImage: string;
  dailyImages: string[];
  startDate: Date | string;
  endDate: Date | string;
  isDaily: boolean;
  image: string[];
  setDocumentReady: (arg: boolean) => void;
  downloadRef?: any;
  setPreviewPDF: (arg: boolean) => void;
  setIsGenerating: (arg: boolean) => void;
  setNumOfGeneratedGraphs: (arg: number) => void;
}

export const DownloadLink = ({
  setNumOfGeneratedGraphs,
  setIsGenerating,
  headerTableImage,
  dailyImages,
  startDate,
  endDate,
  isDaily,
  image,
  setDocumentReady,
  downloadRef,
  setPreviewPDF,
}: IDownloadLink) => {
  const { t } = useTranslation();
  return (
    <PDFDownloadLink
      document={
        <Document>
          <Page style={styles.body} size={"A4"}>
            <View fixed>
              <Image src={netcheckLogo} style={styles.headerLogo} />
              <Text style={styles.headerText}>
                {t("Fixed broadband connection report")}
              </Text>
            </View>

            <View>
              <Image style={styles.headerTable} src={headerTableImage} />
            </View>
          </Page>

          {isDaily && (
            <GraphsContainer src={dailyImages}>
              <Text style={styles.pageTitle}>
                {t("Date from")}{" "}
                {moment(startDate, "MM-DD-yyyy").format("DD.MM.yyyy")} {t("to")}{" "}
                {moment(endDate, "MM-DD-yyyy").format("DD.MM.yyyy")}
              </Text>
            </GraphsContainer>
          )}

          {image.map((el, i: number) => {
            return (
              <GraphsContainer key={i} src={el}>
                <Text style={styles.pageTitle}>
                  {t("Date")}: {moment(el[0]).format("DD.MM.yyyy")}
                </Text>
              </GraphsContainer>
            );
          })}

          <Page style={styles.body} size={"A4"}>
            <View fixed>
              <Image src={netcheckLogo} style={styles.pageLogoFooter} />
            </View>
            <RaportDescription />
          </Page>
        </Document>
      }
      fileName={
        moment(startDate, "MM-DD-yyyy").format("DD.MM.yyyy") ===
        moment(endDate, "MM-DD-yyyy").format("DD.MM.yyyy")
          ? `report ${moment(startDate, "MM-DD-yyyy").format("DD.MM.yyyy")}.pdf`
          : `report ${moment(startDate, "MM-DD-yyyy").format(
              "DD.MM.yyyy"
            )}-${moment(endDate, "MM-DD-yyyy").format("DD.MM.yyyy")}.pdf`
      }
    >
      {({ blob, url, loading, error }) => {
        if (blob && !loading) {
          setDocumentReady(true);
          setNumOfGeneratedGraphs(0);
          setIsGenerating(false);
        }
        return loading ? (
          "Loading document..."
        ) : (
          <div ref={downloadRef}>download</div>
        );
      }}
    </PDFDownloadLink>
  );
};

export default DownloadLink;
