import styled from "styled-components";
import { Route, Routes } from 'react-router-dom';
import { Dashboard } from '@netcheck/dashboard';
import { Suspense, useEffect } from 'react';
import { Box, CssBaseline } from "@mui/material";
import { UiDrawer } from '@netcheck/ui/drawer';
import { UiToolbar } from '@netcheck/ui/toolbar';
import { ProtectedRoute } from "@netcheck/utils/protected-route";
import { useKeycloak } from "@react-keycloak/web";
import { UiNotLogged } from "@netcheck/ui/not-logged"
import { useTranslation } from 'react-i18next';

const StyledApp = styled(Box)`
    min-height: 100vh;
    box-sizing: content-box;
    display: flex;
    background-color: ${({ theme }) => theme.basicColors.BackgroundColor1};
`;

const Fallback = () => {
  return (
    <h1>Loading</h1>
  )
}

export function App() {
  const { keycloak, initialized } = useKeycloak()
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(keycloak?.idTokenParsed?.locale)
    if (!initialized) {
      return;
    } else {
      if (!keycloak.authenticated) {
        keycloak.login()
      }
    }
  }, [initialized, keycloak, i18n])

  return (
    <StyledApp>
      <CssBaseline />
      {keycloak.authenticated ? <>
        <UiDrawer />
        <UiToolbar />
        <ProtectedRoute>
          <Suspense fallback={<Fallback />}>
            <Routes>
              <Route path='/' element={<Dashboard />} />
            </Routes>
          </Suspense>
        </ProtectedRoute>
      </>
        :
        <UiNotLogged />
      }
    </StyledApp>
  );
}

export default App;
