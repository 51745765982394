import styled from "styled-components";
import {  Popover } from "@mui/material";

export const StyledDiv = styled.div`
  position: absolute;
  margin-left: 0;
  padding: 0;
`;

export const StyledPopover = styled(Popover)`
  .MuiPaper-root {
    overflow: auto;
  }
  @media (max-width: 450px) {
    margin-top: 50px;
    margin-bottom: 30px;
  }
`;
