import axios, { AxiosRequestConfig } from "axios";
import keycloak from "./keycloak";
const baseURL = `https://${process.env.NX_API_BASE_URL}/`;

const api = axios.create({
  baseURL
});

api.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    config.headers = {
      Authorization: `Bearer ${keycloak.token}`,
      Accept: "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Origin": "* "
    };
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

api.interceptors.response.use(
  (res) => res,
  (err) => {
    return Promise.reject(err);
  }
);

export { api };
