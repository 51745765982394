import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { globalActions } from '@netcheck/state/global'
import { getInterval } from '@netcheck/utils/functions/get-interval'
import { ICustomDateRange, intervalEnum } from '@netcheck/utils/types'
import { StyledButton, StyledInputLabel } from './styles'

interface IPredefinedInterval {
  el: intervalEnum,
  isDaily?: boolean
}

export const PredefinedInterval = ({ el, isDaily }: IPredefinedInterval) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const setRange = (range: ICustomDateRange) => {
    dispatch(globalActions.setDataRange(range))
    dispatch(globalActions.setLastDateRange(range))
  }

  const handleButtonClick = (el: intervalEnum) => (isDaily ? setRange(getInterval(el)) : dispatch(globalActions.setDataRange(getInterval(el))))

  return (
    <StyledButton variant="text" onClick={() => handleButtonClick(el)}>
      <StyledInputLabel>{t(el)}</StyledInputLabel>
    </StyledButton>
  )
}

export default PredefinedInterval