import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { dateRangeSelector } from "@netcheck/state/global";
import { useSelector } from "react-redux";
import moment from "moment";

export const StyledSpanBold = styled.span`
  font-size: 14px;
  font-weight: 600;
`;

const DateRangeTitleParagraph = styled.p`
  margin-top: 15px;
  font-weight: bold;
  font-size: 12px;
`;

export const DateRangeWidget = () => {
  const { t } = useTranslation();
  const { startDate, endDate } = useSelector(dateRangeSelector);

  return (
    <>
      <DateRangeTitleParagraph>{t("Data range")}</DateRangeTitleParagraph>
      <StyledSpanBold>
        {startDate &&
          ` ${moment(startDate, "MM-DD-yyyy").format("DD.MM.yyyy")} `}
        {endDate &&
          startDate &&
          !moment(startDate).isSame(endDate) &&
          endDate !== "Invalid date" &&
          `- ${moment(endDate, "MM-DD-yyyy").format("DD.MM.yyyy")}`}
      </StyledSpanBold>
    </>
  );
};

export default DateRangeWidget;
