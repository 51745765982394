import { UiStatisticsBox } from '@netcheck/ui/statistics-box';
import { GetAppOutlined } from '@mui/icons-material';
import { StatsItem } from '@netcheck/utils/types';
import { downloadMetricSelector, fetchDeviceData } from '@netcheck/state/device-data';
import { useDispatch, useSelector } from 'react-redux';
import { getFormattedAmount } from '@netcheck/utils/functions';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { metricTypeSelector, dateRangeSelector } from '@netcheck/state/global';
import { AnyStyledComponent } from 'styled-components';


interface IFeatureAverageDownload {
  statsProps?: any,
  dataProps?: any,
  isPDF?: boolean
}

export const FeatureAverageDownload = ({ statsProps, dataProps, isPDF }: IFeatureAverageDownload) => {
  const dispatch = useDispatch();
  const data = useSelector(downloadMetricSelector);
  const metricType = useSelector(metricTypeSelector);
  const { startDate, endDate } = useSelector(dateRangeSelector)
  const { t } = useTranslation()

  const stats: StatsItem[] = [
    {
      title: t('Max. Download Bitrate, Mbps'),
      amount: getFormattedAmount(data?.max)
    },
    {
      title: t('Avg. of Bitrate, Mbps'),
      amount: getFormattedAmount(data?.avg)
    }
  ]

  useEffect(() => {
    if (!dataProps) {
      if (startDate === 'Invalid date' || endDate === 'Invalid date') return
      dispatch(fetchDeviceData('download'))
    }
  }, [dispatch, metricType, startDate, endDate])

  return data && (
    <div>
      <UiStatisticsBox icon={<GetAppOutlined />} title={t("Average Download Bitrate per Day, Mbps")} tooltipText={`${t("downloadTooltipTitle")}: ${t("downloadTooltip")}`} stats={statsProps ? statsProps : stats}
        data={dataProps ? dataProps : data} isPDF={isPDF ? true : false} />
    </div>
  );
};


export default FeatureAverageDownload;
