import { useDispatch, useSelector } from "react-redux";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  StaticDatePicker,
  LocalizationProvider,
  StaticDateRangePicker,
} from "@mui/lab";
import { Stack } from "@mui/material";
import { ICustomDateRange, intervalEnum } from "@netcheck/utils/types";
import { dateRangeSelector, globalActions } from "@netcheck/state/global";
import { DateFilterInputs } from "@netcheck/ui/date-filter-inputs";
import { PredefinedValues } from "@netcheck/ui/date-filter-predefined-values";
import { StyledBox, StylingDiv } from "./styles";
import moment from "moment";

interface IDateRangePicker {
  handleClose?: () => void;
  isGenerating?: boolean;
  isDaily?: boolean;
  inModal?: boolean;
  setDocumentReady?: (arg: boolean) => void;
  setPreviewPDF?: (arg: boolean) => void;
}

export function DatePicker({
  setDocumentReady,
  handleClose,
  isDaily,
  inModal,
  isGenerating,
  setPreviewPDF,
}: IDateRangePicker) {
  const dispatch = useDispatch();
  const { startDate, endDate } = useSelector(dateRangeSelector);

  const setRange = (range: ICustomDateRange) => {
    dispatch(globalActions.setDataRange(range));
    dispatch(globalActions.setLastDateRange(range));
  };

  type ChangeValueType = Date | null;
  type newValueType = ChangeValueType[] | ChangeValueType;

  const handleDatePickerChange = (newValue: newValueType) => {
    console.log(
      newValue,
      moment((newValue as Date[], "MM-DD-yyyy")[0]).format("MM.DD.yyyy")
    );
    if (isDaily) {
      const range = {
        startDate: moment((newValue as Date[])[0]).format("MM.DD.yyyy"),
        endDate: moment((newValue as Date[])[1], "MM-DD-yyyy").format(
          "MM.DD.yyyy"
        ),
        interval: intervalEnum.custom_Interval,
      };

      setRange(range);
    } else {
      dispatch(
        globalActions.setDataRange({
          startDate: moment(newValue as Date, "MM-DD-yyyy").format(
            "MM.DD.yyyy"
          ),
          endDate: moment(newValue as Date, "MM-DD-yyyy").format("MM.DD.yyyy"),
          interval: intervalEnum.custom_Interval,
        })
      );
    }
    if (setDocumentReady) {
      if (setDocumentReady) setDocumentReady(false);
      if (setPreviewPDF) setPreviewPDF(false);
    }
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StylingDiv>
          {isDaily ? (
            <StaticDateRangePicker
              disabled={isGenerating}
              className="staticDateRangePicker"
              allowSameDateSelection={true}
              displayStaticWrapperAs="desktop"
              value={[startDate, endDate]}
              onChange={(newValue: ChangeValueType[]): void =>
                handleDatePickerChange(newValue)
              }
              renderInput={(startProps, endProps) => <span />}
            />
          ) : (
            <StaticDatePicker
              disabled={isGenerating}
              className="datePicker"
              displayStaticWrapperAs="desktop"
              openTo="day"
              value={startDate}
              onChange={(newValue: ChangeValueType): void => {
                handleDatePickerChange(newValue);
              }}
              renderInput={(params) => <span />}
            />
          )}
        </StylingDiv>
      </LocalizationProvider>
      <StyledBox>
        <Stack direction="column" spacing={1} justifyContent="flex-start">
          {!inModal && <PredefinedValues isDaily={isDaily} />}
          {!inModal && (
            <DateFilterInputs isDaily={isDaily} handleClose={handleClose} />
          )}
        </Stack>
      </StyledBox>
    </>
  );
}
