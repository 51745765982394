import Keycloak from "keycloak-js";

const keycloakURL = process.env.NX_KEYCLOAK_URL

export const keycloak = Keycloak({
  url: `https://${keycloakURL}`,
  realm: "probe",
  clientId: "React-auth"
});

export default keycloak;
