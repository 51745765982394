import styled from "styled-components";
import { drawerWidth } from "@netcheck/ui/drawer";
import { AppBar, IconButton, Toolbar, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SettingsDropdown } from "./settings-dropdown/settings-dropdown";
import { Menu, BarChart } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { globalActions } from "@netcheck/state/global";

const StyledUiToolbar = styled.div`
  display: flex;
  justify-content: space-between;
`;
const StyledTypography = styled(Typography)`
  color: ${(props) => props.theme.basicColors.PrimaryColor1};
  display: flex;
`;
const StyledBox = styled(Box)`
  color: ${(props) => props.theme.basicColors.PrimaryColor1};
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`;
const StyledAppBar = styled(AppBar)`
  box-shadow: none;
  padding: 8px 32px;
`;

export const UiToolbar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <StyledUiToolbar>
      <StyledAppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "#F4F4F4",
        }}
      >
        <Toolbar sx={{ borderBottom: "1px solid #E5E5E5" }}>
          <Box>
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={() => dispatch(globalActions.setDrawerOpen(true))}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <Menu />
            </IconButton>
          </Box>

          <StyledTypography variant="h5">
            <BarChart
              sx={{
                fontSize: "30px",
                alignSelf: "center",
                marginRight: "10px",
              }}
            />{" "}
            {t("dashboard")}
          </StyledTypography>

          <StyledBox>
            <SettingsDropdown />
          </StyledBox>
        </Toolbar>
      </StyledAppBar>
    </StyledUiToolbar>
  );
};

export default UiToolbar;
