import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';

export interface ITheme {
  basicColors: Record<string, string>;
  chartsColors: Record<string, string>;
  shadows: Record<string, string>;
  lines: Record<string, string>;
  border: Record<string, string>;
  font: Record<string, string>;
  breakpoints: Record<string, string>;
}

export const theme: ITheme = {
  basicColors: {
    PrimaryColor1: "#333333",
    PrimaryColor2: "#666666",
    PrimaryColor3: "#898989",
    PrimaryColor4: "#00000026",
    PrimaryColor5: "#E5E5E5",
    SecondaryColor: "#E94645",
    BackgroundColor1: "#F4F4F4",
    BackgroundColor2: "#FFFFFF",

  },
  chartsColors: {
    ResultColor1: "#457DE9",
    ResultColor2: "#F19A33",
    ResultColor3: "#239A98",
    ResultColor4: "#675555",
    ResultColor5: "#DB66C8",
    ResultColor6: "#A8AABC",
    ResultColor7: "#0B308F",
    ResultColor8: "#A4DD9F",
    ResultColor9: "#F3704B",
    ResultColor10: "#2AA8B9",
    ResultColor11: "#6E33BA",
    ResultColor12: "#D44352"
  },
  shadows: {
    ChartShadow: "0px 3px 40px rgba(0, 0, 0, 0.08)",
    PopUpShadow: "0px 4px 60px rgba(0, 0, 0, 0.12)"
  },
  lines: {
    PrimaryLine: "0.8px solid #E5E5E5",
    ChartLine: "0.8px dashed #D9D9D9",
    ExpectedResultLine: "1px dashed #333333"
  },
  border: {
    Radius: '4px'
  },
  font: {
    MainFont: "Montserrat, sans-serif"
  },
  breakpoints: {
    xs: '0px',
    sm: '600px',
    md: '900px',
    lg: '1200px',
    xl: '1536px'
  }
};

const muiTheme = createTheme({
  typography: {
    fontFamily: theme.font.MainFont
  }
});

export const Theme: React.FC = ({ children }) => (
  <MuiThemeProvider theme={muiTheme}>
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  </MuiThemeProvider>
);

export default Theme;
