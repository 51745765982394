import { Image, Page, View } from '@react-pdf/renderer'
import { styles } from '@netcheck/feature/preview-pdf'
import netcheckLogo from 'apps/probe-fe/src/assets/logos/netcheck-logo.png'

interface IGraphsContainer {
  children: any
  src: any,

}

export const GraphsContainer = ({ children, src }: IGraphsContainer) => {
  return (
    <Page style={styles.body} size={'A4'} >
      <View fixed>
        <Image src={netcheckLogo} style={styles.pageLogo} />
      </View>
      <View >
        {children}
        <Image style={styles.imageChartWithWidget} src={src[1]} />
        <Image style={styles.imageChartWithWidget} src={src[2]} />
        <Image style={styles.imageChart} src={src[3]} />
        <Image style={styles.imageChart} src={src[4]} />
      </View>
    </Page>
  )
}

export default GraphsContainer