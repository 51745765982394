import {
  dateRangeSelector,
  metricTypeSelector,
  operatorDataSelector,
} from "@netcheck/state/global";
import moment from "moment";
import { useSelector } from "react-redux";
import styled from "styled-components";

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  padding: 5px 10px;
  & div {
    padding: 5px;
    border: 1px solid #dddddd;
  }
  & div:nth-child(n + 5) {
    font-weight: bold;
  }
`;

export const HeaderTableRaport = () => {
  const intervalType = useSelector(metricTypeSelector);
  const { deviceId, operatorId } = useSelector(operatorDataSelector);
  const { startDate, endDate } = useSelector(dateRangeSelector);

  return (
    <GridContainer theme={{ intervalType: intervalType }} id={"headerTable"}>
      <div>Device ID:</div>
      <div>Operator:</div>
      <div>Time frame:</div>
      <div>Type:</div>

      <div>{deviceId}</div>
      <div>{operatorId}</div>
      <div>
        {intervalType === "Daily"
          ? `${moment(startDate, "MM-DD-yyyy").format("yyyy.MM.DD")} - ${moment(
              endDate,
              "MM-DD-yyyy"
            ).format("yyyy.MM.DD")}`
          : `${moment(startDate, "MM-DD-yyyy").format("yyyy.MM.DD")}`}
      </div>
      <div>{intervalType} report</div>
    </GridContainer>
  );
};

export default HeaderTableRaport;
