import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import {
  IDeviceDataState,
  deviceDataReducer
} from "@netcheck/state/device-data";
import { globalReducer, IGlobalState } from "@netcheck/state/global";

export interface IState {
  global: IGlobalState;
  deviceData: IDeviceDataState;
}

const rootReducer = combineReducers<IState>({
  global: globalReducer,
  deviceData: deviceDataReducer
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}),
});
export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
