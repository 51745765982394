import styled from 'styled-components';
import { Grid } from '@mui/material';

export const StyledUiDateFilter = styled.div`
   color: ${(props) => props.theme.basicColors.PrimaryColor1};
    position: 'relative';
    width: 100%;
    padding-left: 20px;
    margin-right:0;
    @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    line-height: 1;
    margin-top: 20px;
    margin-left: 0;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-left: 0px;
  }
`
export const StyledGrid = styled(Grid)`
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-left: 0 !important;  
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-left: 16px !important;
  }
`
export const RadioGrid = styled(Grid)`
  padding-left: 0 !important;`
